@import '../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 440px;
  padding: 56px 0 40px;
  background-color: white;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.4);
  border-radius: 16px;

  > .logo {
    width: 200px;
    height: 74px;
    background-image: url('../assets/logo.svg');
    background-size: cover;
  }
  > .fieldsWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    > .field {
      width: 320px;
      height: 48px;
      border-radius: 8px;
      border: 1px solid $line-color;
      text-align: center;
      font-size: 16px;

      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
  > .message {
    display: flex;
    align-items: center;
    margin-top: 24px;
    height: 24px;
    font-size: 14px;
    color: red;
  }
  > .button {
    margin-top: 24px;
    width: 96px;
    height: 48px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 8px;
    background-color: $tint-color;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;

    &:hover {
      background-color: darken($tint-color, 5%);
    }
    &.isProcessing {
      width: 48px;
      color: transparent;
      border-radius: 50%;

      > .spinner {
        opacity: 1;
      }
    }
    > .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 48px;
      height: 48px;
      background-image: url('../assets/spinner.svg');
      background-size: cover;
      opacity: 0;
      transform: translate(-50%, -50%);
      transition: all 0.2s;
    }
  }
}
