@import '../styles/common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px;
  background-color: white;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  transform: scale(0);
  transition: all 0.2s;

  &.visible {
    transform: scale(1);
  }
  > .close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 24px;
    right: 24px;
    width: 32px;
    height: 32px;
    background-color: rgba($tint-color, 0.3);
    color: $tint-color;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: darken(rgba($tint-color, 0.3), 5%);
    }
  }
  > .logo {
    width: calc(200px * 0.7);
    height: calc(74px * 0.7);
    background-image: url('../assets/logo.svg');
    background-size: cover;
  }
  > .wrapper {
    margin-top: 40px;
    display: flex;
    flex-direction: row;

    > .menu {
      width: 240px;
      height: 320px;
      padding: 16px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
      border-radius: 16px;
      position: relative;
      cursor: pointer;
      transition: all 0.2s;

      &:not(:first-child) {
        margin-left: 24px;
      }
      &:hover {
        transform: scale(1.05);
      }
      &.disabled {
        background-color: rgba(black, 0.2);

        > .image {
          opacity: 0.3;
        }
      }
      > .title {
        color: $tint-color;
        font-size: 22px;
        font-weight: bold;
      }
      > .subtitle {
        margin-top: 8px;
        font-size: 14px;
        color: gray;
      }
      > .image {
        width: 120px;
        height: 120px;
        background-size: cover;
        position: absolute;
        bottom: 16px;
        right: 16px;

        &.clip1 {
          background-image: url('../assets/01.png');
        }
        &.clip2 {
          background-image: url('../assets/02.png');
        }
        &.clip3 {
          background-image: url('../assets/03.png');
        }
      }
    }
  }
}
